import React from "react";
import { general } from "./data/steps";

export const TakeOneStep = ({ lang }) => {
  const generalPath = general[lang || "en"];

  const line1Styles = {
    fontSize: "18px",
    lineHeight: "18px",
    fontWeight: 900,
    color: "white",
    textTransform: "uppercase",
  };

  const line2Styles = {
    fontSize: "48px",
    color: "white",
  };

  const line3Styles = {
    fontSize: "13px",
    lineHeight: "13px",
    color: "#3bbebe",
    fontWeight: 900,
    textTransform: "uppercase",
  };

  return (
    <div className="take-one-step text-center">
      <div className="line-1" style={line1Styles}>
        {generalPath.lineOne}
      </div>
      <div className="line-2 font-carved" style={line2Styles}>
        {generalPath.lineTwo}
      </div>
      <div className="line-3" style={line3Styles}>
        {generalPath.lineThree}
      </div>
    </div>
  );
};
