import React from "react";
import { StaticImage } from "gatsby-plugin-image";

/**
 * module: the section per the docs
 * w: the width when not hovered
 * x: the left position in the circle
 * y: the top position in the circle
 * o: the amount to offset the image when zooming in
 * i: is the tab order
 * t: the title offset
 *
 * The hover width is set in Step.js
 */
export const steps = {
  en: [
    {
      module: "new-believer",
      w: 332,
      x: 831,
      y: 65,
      o: -65,
      i: 2,
      t: 30,
      mobile: "New Believer?",
      title: "New Believer?",
      step: "Where do I start?",
      description:
        "I’m looking for information, inspiration, and a simple way to start my spiritual journey.",
      image: (
        <StaticImage
          width={600}
          height={600}
          src={"../images/victory-onestep-landing-1.png"}
          alt={"Where do I start?"}
        />
      ),
      url: "https://www.connecttovictory.com/believe",
    },

    {
      module: "water-baptism",
      w: 396,
      x: 945,
      y: 198,
      o: -40,
      i: 3,
      t: 20,
      mobile: "Get Baptized",
      title: "Get Baptized",
      step: "Take a step of faith",
      description:
        "Are you ready to fully embrace a life of faith in Jesus? Learn about baptism.",
      url: "https://new.victoryatl.com/global/d/baptism",
      image: (
        <StaticImage
          width={600}
          height={600}
          src={"../images/victory-onestep-landing-2.png"}
          alt={"Take a step of faith"}
        />
      ),
    },

    {
      module: "discover-victory",
      w: 292,
      x: 552,
      y: 99,
      o: -55,
      i: 1,
      t: 40,
      mobile: "Discover Victory",
      title: "Discover Victory",
      step: "Discover Victory’s heart",
      description:
        "Come experience who we are as a community and learn about our mission.",
      url: "https://new.victoryatl.com/global/p/discover",
      image: (
        <StaticImage
          width={600}
          height={600}
          src={"../images/victory-onestep-landing-3.png"}
          alt={"Discover Victory’s heart"}
        />
      ),
    },

    {
      module: "membership",
      w: 388,
      x: 200,
      y: 43,
      o: -55,
      i: 8,
      t: 45,
      mobile: "Join Victory",
      title: "Join Victory",
      step: "Join Us",
      description: "Become part of the Victory family.",
      url: "https://victoryatl.com/membership/",
      image: (
        <StaticImage
          width={600}
          height={600}
          src={"../images/victory-onestep-landing-5.png"}
          alt={"Join Us"}
        />
      ),
    },

    {
      module: "forward",
      w: 323,
      x: 77,
      y: 302,
      o: -5,
      i: 7,
      mobile: "Move Forward",
      title: "Move Forward",
      step: "How can God help me grow?",
      description:
        "Heal and live a healthy life by learning to hear God's voice.",
      url: "https://victoryatl.com/forward/",
      image: (
        <StaticImage
          width={600}
          height={600}
          src={"../images/victory-onestep-landing-4.png"}
          alt={"How can God help me grow?"}
        />
      ),
    },

    {
      module: "small-groups",
      w: 369,
      x: 206,
      y: 510,
      o: -30,
      i: 6,
      t: 20,
      mobile: "Community",
      title: "Community",
      step: "Find a community of encouragement",
      description: "Life is better with others. Come find your people.",
      url: "https://new.victoryatl.com/global/p/smallgroups",
      image: (
        <StaticImage
          width={600}
          height={600}
          src={"../images/victory-onestep-landing-6.png"}
          alt={"Find a community of encouragement"}
        />
      ),
    },

    {
      module: "serve",
      w: 537,
      x: 472,
      y: 462,
      o: -40,
      i: 5,
      t: 20,
      mobile: "Serve",
      title: "Serve",
      step: "I’m ready to give back",
      description: "Discover how you can serve others with Victory.",
      url: "https://victoryatl.com/serve",
      image: (
        <StaticImage
          width={600}
          height={600}
          src={"../images/victory-onestep-landing-7.png"}
          alt={"I’m ready to give back"}
        />
      ),
    },

    {
      module: "leadership",
      w: 427,
      x: 845,
      y: 462,
      o: -50,
      i: 4,
      t: 15,
      mobile: "Lead",
      title: "Lead",
      step: "What does leadership look like?",
      description:
        "Learn what leadership means at Victory, and explore the pathways to becoming a leader.",
      url: "https://victoryatl.com/leadership/",
      image: (
        <StaticImage
          width={600}
          height={600}
          src={"../images/victory-onestep-landing-8.png"}
          alt={"What does leadership look like?"}
        />
      ),
    },
  ],

  es: [
    {
      module: "new-believer",
      w: 332,
      x: 831,
      y: 65,
      o: -65,
      i: 2,
      t: 30,
      mobile: "¿Nuevos creyentes?",
      title: "¿Nuevos creyentes? ",
      step: "¿Dónde empiezo?",
      description:
        "Estoy buscando información, inspiración y una forma sencilla de comenzar mi crecimiento espiritual",
      url: "https://www.connecttovictory.com/es/nueva-fe",
      image: (
        <StaticImage
          width={600}
          height={600}
          src={"../images/victory-onestep-landing-1.png"}
          alt={"¿Dónde empiezo?"}
        />
      ),
    },

    {
      module: "water-baptism",
      w: 396,
      x: 945,
      y: 198,
      o: -40,
      i: 3,
      t: 20,
      mobile: "Bautízate",
      title: "Bautízate",
      step: "Da un paso de fe ",
      description:
        "¿Estás listo para abrazar completamente una vida de fe en Jesús? Aprende sobre el bautismo",
      url: "https://new.victoryatl.com/global/d/baptism/espaol",
      image: (
        <StaticImage
          width={600}
          height={600}
          src={"../images/victory-onestep-landing-2.png"}
          alt={"Da un paso de fe"}
        />
      ),
    },

    {
      module: "discover-victory",
      w: 292,
      x: 552,
      y: 99,
      o: -55,
      i: 1,
      t: 40,
      mobile: "Descubre Victory",
      title: "Descubre Victory",
      step: "Descubre el corazón de Victory",
      description:
        "Ven a experimentar quiénes somos como comunidad y aprende sobre nuestra misión",
      url: "https://new.victoryatl.com/global/p/discover/es",
      image: (
        <StaticImage
          width={600}
          height={600}
          src={"../images/victory-onestep-landing-3.png"}
          alt={"Descubre el corazón de Victory"}
        />
      ),
    },

    {
      module: "membership",
      w: 388,
      x: 200,
      y: 43,
      o: -55,
      i: 8,
      t: 45,
      mobile: "Únete a Victory",
      title: "Únete a Victory",
      step: "Únete",
      description: "Forma parte de la familia de Victory.",
      url: "https://victoryatl.com/victoryvida/membresia/",
      image: (
        <StaticImage
          width={600}
          height={600}
          src={"../images/victory-onestep-landing-5.png"}
          alt={"Únete"}
        />
      ),
    },

    {
      module: "forward",
      w: 323,
      x: 77,
      y: 302,
      o: -5,
      i: 7,
      mobile: "Todos debemos avanzar",
      title: "Todos debemos avanzar",
      step: "¿Cómo puede Dios ayudarme a crecer? ",
      description:
        "Sana y vive una vida saludable aprendiendo a escuchar la voz de Dios",
      url: "https://victoryatl.com/forward/",
      image: (
        <StaticImage
          width={600}
          height={600}
          src={"../images/victory-onestep-landing-4.png"}
          alt={"¿Cómo puede Dios ayudarme a crecer? "}
        />
      ),
    },

    {
      module: "small-groups",
      w: 369,
      x: 206,
      y: 510,
      o: -30,
      i: 6,
      t: 20,
      mobile: "Encuentra tu comunidad",
      title: "Encuentra tu comunidad",
      step: "Encuentra una comunidad de aliento",
      description: "La vida es mejor con otros a tu lado. ¡Ven y encuentra a tu gente¡",
      url: "https://victoryatl.com/victoryvida/grupospequenos1/",
      image: (
        <StaticImage
          width={600}
          height={600}
          src={"../images/victory-onestep-landing-6.png"}
          alt={"Encuentra una comunidad de aliento"}
        />
      ),
    },

    {
      module: "serve",
      w: 537,
      x: 472,
      y: 462,
      o: -40,
      i: 5,
      t: 20,
      mobile: "Estoy listo para servir",
      title: "Estoy listo para servir",
      step: "Sirve",
      description: "Descubre cómo puedes servir a los demás en Victory",
      url: "https://victoryatl.com/victoryvida/oportunidadesdeservicio/",
      image: (
        <StaticImage
          width={600}
          height={600}
          src={"../images/victory-onestep-landing-7.png"}
          alt={"Estoy listo para servir"}
        />
      ),
    },

    {
      module: "leadership",
      w: 427,
      x: 845,
      y: 462,
      o: -50,
      i: 4,
      t: 15,
      mobile: "Lidera",
      title: "Lidera",
      step: "¿Qué es Liderazgo?",
      description:
        "Aprenda lo que significa liderazgo en Victory y explore los caminos para convertirse en un líder",
      url: "https://victoryatl.com/victoryvida/liderazgo/",
      image: (
        <StaticImage
          width={600}
          height={600}
          src={"../images/victory-onestep-landing-8.png"}
          alt={"¿Qué es Liderazgo?"}
        />
      ),
    },
  ],
};

export const general = {
  en: {
    lineOne: "Wherever You Are",
    lineTwo: "Take One Step",
    lineThree: "Explore What’s Next...",
  },
  es: {
    lineOne: "Donde quiera que estés",
    lineTwo: "Da un paso",
    lineThree: "Explora lo que sigue...",
  },
};
