import React, { useEffect, useState } from "react";
import "./Step.scss";

export default function Step({
  mobile,
  title,
  step,
  description,
  url,
  image,
  module,
  width,
  stepWidth,
  index,
  offset,
  titleOffset = 0,
}) {
  const [zoom, setZoom] = useState(false);
  const [zoomWidth, setZoomWidth] = useState(width);
  const [zoomTitleOffset, setZoomTitleOffset] = useState("");
  const [backgroundOffset, setBackgroundOffset] = useState(0);
  const containerStyles = {
    color: "white",
  };

  /**
   * scale up the modules and shift the background image to better fit the modules.
   * Offsets are defined in data/steps.js as the `o` property
   */
  useEffect(() => {
    if (zoom) {
      setZoomWidth(stepWidth);
      setBackgroundOffset(offset);
      setZoomTitleOffset("0");
    } else {
      setZoomWidth(width);
      setBackgroundOffset(0);
      setZoomTitleOffset(`calc(50% + ${titleOffset}px)`);
    }
  }, [zoom, offset, width, stepWidth, titleOffset]);

  return (
    <div
      className={"font-proxima p-0"}
      style={containerStyles}
      onMouseEnter={() => setZoom(true)}
      onMouseLeave={() => setZoom(false)}
      role="none"
    >
      <div className="mobile-title mobile-only">
        <a
          href={url}
          target="_blank"
          className="block"
          rel="noreferrer"
          role="menuitem"
        >
          <h2 className="px-3">{mobile}</h2>
        </a>
      </div>
      <div className="hide-mobile module-wrapper">
        <a href={url} target="_blank" rel="noreferrer">
          <div
            className={`module module-${module}`}
            style={{
              width: `${zoomWidth}px`,
              height: `${zoomWidth}px`,
              top: `${-(zoomWidth - width) / 2}px`,
              left: `${-(zoomWidth - width) / 2}px`,
            }}
          >
            <div
              className="background"
              style={{
                top: `${backgroundOffset}px`,
                width: `${zoomWidth}px`,
                height: `${zoomWidth}px`,
              }}
            >
              {image}
            </div>
            <div className="copy">
              <div className="top">
                <div
                  className="title text-md-center"
                  style={{ top: zoomTitleOffset }}
                  tabIndex={100 + index}
                  role="menuitem"
                >
                  {title}
                </div>
              </div>
              <div className="bottom">
                <div className="step font-carved">{step}</div>
                <div className="description">{description}</div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}
