import React from "react";

import "./OneStepContainer.scss";
import { steps } from "./data/steps";
import Step from "./Step";
import { TakeOneStep } from "./TakeOneStep";
import { MobileBackground } from "./MobileBackground";

export const OneStepContainer = ({ lang, stepWidth }) => {

  console.log(steps)
  return (
    <div className="one-step-container pt-1 pt-md-0 w-full position-relative">
      <div
        className="mobile-only position-absolute z-index-0"
        style={{ width: "100%", height: "100%", top: "-70px" }}
      >
        <MobileBackground
          bgColor={"transparent"}
          className={"mobile-background"}
          styles={{ width: "100%", height: "100%" }}
        />
      </div>
      <div className="take-one-step-wrapper">
        <TakeOneStep lang={lang} />
      </div>
      <div className="steps-wrapper position-relative position-absolute-xl z-index-1">
        {
          lang === 'en' ?
            steps.en.map((step, i) => (
              <div
                key={step.mobile}
                style={{
                  left: `${step.x}px`,
                  top: `${step.y}px`,
                }}
                className="step-wrapper"
              >
                <Step
                  mobile={step.mobile}
                  img={step.img}
                  image={step.image}
                  title={step.title}
                  step={step.step}
                  description={step.description}
                  url={step.url}
                  module={step.module}
                  width={step.w}
                  offset={step.o}
                  stepWidth={stepWidth}
                  index={step.i}
                  titleOffset={step.t}
                />
              </div> ))
            : 
              lang === 'es' ?
                steps.es.map((step, i) => (
                  <div
                    key={step.mobile}
                    style={{
                      left: `${step.x}px`,
                      top: `${step.y}px`,
                    }}
                    className="step-wrapper"
                  >
                    <Step
                      mobile={step.mobile}
                      img={step.img}
                      image={step.image}
                      title={step.title}
                      step={step.step}
                      description={step.description}
                      url={step.url}
                      module={step.module}
                      width={step.w}
                      offset={step.o}
                      stepWidth={stepWidth}
                      index={step.i}
                      titleOffset={step.t}
                    />
                  </div> 
                ))
                : undefined
        }
      </div>
    </div>
  );
};
