import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

export const MobileBackground = ({ className, bgColor, styles }) => {
  const data = useStaticQuery(graphql`
    {
      background: file(
        relativePath: { eq: "victory-onestep-mobile-bg-crop.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const imageData = data.background.childImageSharp.fluid;
  return (
    <BackgroundImage
      className={className}
      fluid={imageData}
      backgroundColor={bgColor}
      style={styles}
    />
  );
};
