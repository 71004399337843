import React, { useEffect } from "react";
import "../styles/app.scss";
import { useTheme } from "../hooks/useTheme";

export default function Layout({ children }) {
  const theme = useTheme();

  // apply css custom properties from WordPress
  useEffect(() => {
    const sheet = document.styleSheets[0];
    sheet.insertRule(theme.cssVariables);
  }, [theme.cssVariables]);

  return (
    <div className="layout-background w-full">
      <main className={"container px-0"}>{children}</main>
    </div>
  );
}
